var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"prospects-page","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.prospects,"search":_vm.search,"loading":_vm.loading,"loading-text":"Загружается... Пожалуйста ждите"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Мои заявки")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.phone_whatsapp",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.phone_whatsapp))])]}},{key:"item.phone_viber",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.phone_viber))])]}},{key:"item.instrument",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.instrument)+" ")]}},{key:"item.action_bot",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorActionBot(item.action_bot),"dark":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.action_bot)+" ")])]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStep(item.step),"dark":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.step)+" ")])]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [(item.result)?_c('v-chip',{attrs:{"color":_vm.getColorResult(item.result),"dark":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.result)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.showProspect(item.id)}}},[_vm._v(" mdi-account-circle ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteProspect(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")]),_c('router-link',{attrs:{"to":{name: 'myProspectsBasket'}}},[_vm._v("Корзина")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }